@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@100;400;700&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #1E2C39;
}

h2 {
  font-size: 4rem;
  line-height: 4rem;
  margin: 0;
  text-transform: uppercase;
}

h3 {
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
  text-transform: uppercase;
}

p {
  font-size: 1.2rem;
}

.sans {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.serif {
  font-family: "Playfair Display", serif;
  font-weight: lighter;
}

.btn {
  border-radius: 9999px;
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: white;
  background: #1E2C39;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  padding: 20px 20px;
  width: 100%;
}
.btn:hover {
  cursor: pointer;
}

.contentWrap {
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
}

@keyframes floatRight {
  0% {
    right: -10%;
  }
  100% {
    right: -100%;
  }
}
@keyframes floatLeft {
  0% {
    left: -10%;
  }
  100% {
    left: -100%;
  }
}
@keyframes spin {
  0%, 19% {
    content: "Buy";
    transform: translateY(0);
  }
  20%, 38% {
    content: "Sell";
    transform: translateY(0);
    height: 100%;
  }
  40%, 58% {
    content: "Rent";
    transform: translateY(0);
  }
  60%, 78% {
    content: "Lease";
    transform: translateY(0);
  }
  80%, 98% {
    content: "Invest";
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
.hero {
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#ADD1DF, #ffffff);
}
.hero article {
  text-align: center;
  position: relative;
  top: -100px;
  padding: 0 20px;
}
.hero h1 {
  font-family: "Playfair Display", serif;
  font-weight: lighter;
  font-size: 1.6rem;
  line-height: 3rem;
  width: 100%;
  color: #30465a;
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}
.hero h1 .sans {
  position: relative;
  display: inline-block;
  color: #1E2C39;
  font-size: 4rem;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}
.hero h1 .sans:before {
  content: "Buy";
  text-align: center;
  display: inline-block;
  transform: translateY(100px);
  animation: spin 10s forwards infinite;
}
.hero .cloud {
  position: absolute;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}
.hero .cloud.one {
  width: 100%;
  height: 100%;
  left: -10%;
  top: -10%;
  animation: floatLeft 200s linear forwards;
}
.hero .cloud.two {
  width: 100%;
  height: 100%;
  right: -10%;
  top: 10%;
  z-index: 9;
  background-position: 100%;
  animation: floatRight 200s linear forwards;
}
.hero .skyline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  pointer-events: none;
}
.hero .btn {
  width: auto;
}

.intro {
  min-height: 100dvh;
  background: linear-gradient(to bottom right, #FFE6D6, #A06D57);
}
.intro section {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.intro section .copy,
.intro section .headshot {
  flex: 0 0 50%;
  box-sizing: border-box;
}
.intro section .headshot {
  align-self: flex-start;
  width: 100%;
  min-height: 400px;
  -webkit-filter: drop-shadow(50px 0 20px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(50px 0 20px rgba(0, 0, 0, 0.2));
  position: relative;
}
.intro section .headshot img {
  width: 120%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.intro section .copy {
  padding: 40px 40px 0;
  color: #1E2C39;
  box-sizing: border-box;
}
.intro section .copy p {
  margin: 30px 0 40px;
}
.intro section .copy .btn {
  color: #FFE6D6;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  background: #CE011F;
  overflow: hidden;
  box-sizing: border-box;
  padding: 40px;
}
.marquee .track {
  display: flex;
  user-select: none;
  will-change: transform;
  animation: marquee 10s linear infinite;
}
.marquee .track article {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 200%;
}
.marquee .track article img {
  max-height: 30px;
}

.carousel {
  overflow: hidden;
}

.carouselWrap {
  width: 100%;
  display: flex;
  transition: all 0.5s ease-in-out;
}
.carouselWrap .slide {
  flex: 0 0 100%;
  min-height: 1200px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
}
.carouselWrap .slide:nth-child(1) {
  background: #3C4632;
}
.carouselWrap .slide:nth-child(1) .slideCopy {
  color: #FFE6D6;
}
.carouselWrap .slide:nth-child(1) .slideCopy .btn {
  background: #FFE6D6;
  color: #3C4632;
}
.carouselWrap .slide:nth-child(1) .slideCopy .btn.arrow:before, .carouselWrap .slide:nth-child(1) .slideCopy .btn.arrow:after {
  background: #3C4632;
}
.carouselWrap .slide:nth-child(2) {
  background: #EBBE50;
}
.carouselWrap .slide:nth-child(2) .slideCopy {
  color: #443626;
}
.carouselWrap .slide:nth-child(2) .slideCopy .btn {
  background: #443626;
  color: #EBBE50;
}
.carouselWrap .slide:nth-child(2) .slideCopy .btn.arrow:before, .carouselWrap .slide:nth-child(2) .slideCopy .btn.arrow:after {
  background: #EBBE50;
}
.carouselWrap .slide:nth-child(3) {
  background: #5F3244;
}
.carouselWrap .slide:nth-child(3) .slideCopy {
  color: #FFE6D6;
}
.carouselWrap .slide:nth-child(3) .slideCopy .btn {
  background: #FFE6D6;
  color: #5F3244;
}
.carouselWrap .slide:nth-child(3) .slideCopy .btn.arrow:before, .carouselWrap .slide:nth-child(3) .slideCopy .btn.arrow:after {
  background: #5F3244;
}
.carouselWrap .slide .slideCopy {
  flex: 0 1 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
}
.carouselWrap .slide .slideCopy h3 {
  font-size: 3rem;
  line-height: 3rem;
  font-family: "Playfair Display", serif;
  font-weight: lighter;
  margin: 0;
  text-transform: uppercase;
}
.carouselWrap .slide .slideCopy p {
  margin: 40px 0;
}
.carouselWrap .slide .slideCopy .btnWrap {
  display: flex;
  align-items: center;
}
.carouselWrap .slide .slideCopy .btn {
  flex: 0 1 100%;
}
.carouselWrap .slide .slideCopy .arrow {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  position: relative;
}
.carouselWrap .slide .slideCopy .arrow:after, .carouselWrap .slide .slideCopy .arrow:before {
  content: "";
  display: block;
  background: inherit;
  width: 20%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
}
.carouselWrap .slide .slideCopy .arrow.next {
  margin-left: 10px;
}
.carouselWrap .slide .slideCopy .arrow.next:before {
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: right;
}
.carouselWrap .slide .slideCopy .arrow.next:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: right;
}
.carouselWrap .slide .slideCopy .arrow.prev {
  margin-right: 10px;
}
.carouselWrap .slide .slideCopy .arrow.prev:before {
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: left;
}
.carouselWrap .slide .slideCopy .arrow.prev:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: left;
}
.carouselWrap .slide .slideImage {
  flex: 0 0 40%;
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  box-shadow: 50px 50px 100px rgba(0, 0, 0, 0.5);
}

.testimonials {
  background: #1E2C39;
  color: #FFE6D6;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 40px;
  position: relative;
  z-index: 2;
}
.testimonials .testimonialWrap {
  padding: 20px 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.testimonials .testimonialWrap.show {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.testimonials .ranking {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.testimonials .ranking .star {
  font-size: 1.4rem;
  color: #EBBE50;
}
.testimonials h3 {
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
  text-transform: uppercase;
}
.testimonials blockquote {
  margin: 0;
  max-width: 800px;
  font-size: 1rem;
  padding: 20px 0;
  color: white;
  position: relative;
}
.testimonials blockquote:before {
  content: '"';
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -20px;
  left: -30px;
  font-family: serif;
}
.testimonials cite {
  text-align: right;
  display: block;
  transform: translate(0, -20px);
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 10px;
}
.testimonials nav {
  display: flex;
  gap: 20px;
}
.testimonials .dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  padding: 0;
  opacity: 0.2;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}
.testimonials .dot.active {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.booking {
  background: #1E2C39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.booking article {
  width: 100%;
}
.booking .copy {
  max-width: 800px;
  color: #FFE6D6;
  text-align: center;
  box-sizing: border-box;
  padding: 40px;
}
.booking .copy h4 {
  margin: 0;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
}
.booking .copy p {
  margin-bottom: 0;
  color: white;
}
.booking .calendly-inline-widget {
  height: 1100px !important;
}

.footer {
  background: #1E2C39;
  text-align: center;
  padding-bottom: 40px;
}
.footer a {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
}
.footer p {
  color: #cccccc;
  font-size: 0.8rem;
  margin: 0;
}
.footer p.dev {
  margin-top: 10px;
}

@media (min-width: 768px) {
  h2, h3 {
    font-size: 4rem;
    line-height: 4rem;
  }
  p {
    font-size: 1.2rem;
  }
  .btn {
    padding: 20px 40px;
  }
  .hero {
    min-height: 100dvh;
  }
  .hero article {
    top: -150px;
  }
  .hero h1 {
    font-size: 2.4rem;
    letter-spacing: 2px;
  }
  .hero h1 .sans {
    font-size: 10rem;
    line-height: 12rem;
    text-align: center;
  }
  .hero h1 .sans:before {
    text-align: center;
  }
  .hero .cloud.one {
    z-index: inherit;
  }
  .hero .cloud.two {
    width: 100%;
    height: 50%;
    right: -10%;
    top: 10%;
    z-index: 9;
    background-position: 100%;
    animation: floatRight 200s linear forwards;
  }
  .hero .skyline {
    height: 250px;
  }
  .intro {
    min-height: auto;
  }
  .intro section {
    flex-direction: row;
  }
  .intro section .headshot {
    min-height: 100dvh;
    align-self: flex-start;
  }
  .intro section .headshot img {
    width: 95%;
  }
  .carousel .slide {
    height: 100vh;
    min-height: auto;
    flex-direction: row;
    padding: 40px;
    box-sizing: border-box;
  }
  .carousel .slide .slideImage {
    margin: 40px;
    border-radius: 40px;
  }
  .carousel .slide .slideCopy {
    margin-top: 0;
    padding: 40px;
  }
  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  .marquee .track article {
    min-width: 100%;
  }
  .marquee .track article img {
    max-height: 50px;
  }
  .testimonials {
    padding: 100px;
  }
  .testimonials blockquote {
    font-size: 1.2rem;
  }
  .booking {
    padding: 100px 0 40px;
  }
  .booking .copy {
    padding: 0;
  }
  .booking .copy h4 {
    font-size: 2.6rem;
    line-height: 2.6rem;
    font-weight: lighter;
  }
  .booking .calendly-inline-widget {
    height: 700px !important;
  }
}
@media (min-width: 1200px) {
  .hero .cloud.one {
    width: 50%;
    background-position: 50%;
  }
  .hero .cloud.two {
    width: 80%;
    background-position: 50%;
  }
}

